import { compose, applyMiddleware, createStore } from "redux"
import thunkMiddleware from "redux-thunk"
import rootReducer, { INITIAL_STATE } from "../reducers"

const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
			{
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
			}
		)
		: compose



const enhancer = composeEnhancers(
	applyMiddleware(thunkMiddleware)
)

const store = createStore(rootReducer, INITIAL_STATE, enhancer)

export default store
