export const SUBSCRIBE = "/subscribe1"
export const NEWSUBSCRIBER = "/newsubscriber"
export const SIGN_IN = "/signin"
export const SIGN_OUT = "/signedout"
export const EDIT = "/edit"
export const EXAMPLE = "/example"
export const SOLUTIONS = "/"
export const PRICING = "/pricing"
export const ONBOARDING = "/onboard"
export const ACCOUNT = "/account"
export const VERSION = "/version"