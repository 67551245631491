
import React, { Suspense } from "react";
import { compose } from "recompose";

import CssBaseline from "@material-ui/core/CssBaseline";
import "typeface-roboto";
import { theme } from "./theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import withAuthentication from "../Session/withAuthentication";

import { HashRouter as Router, Route } from "react-router-dom";
import * as routes from "../../constants/routes";
import ErrorBoundary from "../ErrorBoundary";
import LoadingSpinner from "../LoadingSpinner";
import Notifier from "../Notifier";
import SignOut from "../SignOut";

const AccountPage = React.lazy(() => import('../AccountPage'));
const EventstormingPage = React.lazy(() => import('../EventstormingPage'));
const PricingPage = React.lazy(() => import('../PricingPage'));
const SolutionsPage = React.lazy(() => import('../SolutionsPage'));
const NewSubscriberPage = React.lazy(() => import('../Subscription/NewSubscriberPage'));
const PurchaseSubscription = React.lazy(() => import('../Subscription/PurchaseSubscription'));

const App = () =>
	<ErrorBoundary>
		<MuiThemeProvider theme={theme}>
			<React.Fragment>
				<CssBaseline />
				<Router>
					<div className="app">
						<Notifier />
						<Suspense fallback={<LoadingSpinner />}>
							<Route exact path={routes.SUBSCRIBE} component={() => <PurchaseSubscription />} />
							<Route exact path={routes.NEWSUBSCRIBER} component={() => <NewSubscriberPage />} />
							<Route exact path={routes.SIGN_OUT} component={() => <SignOut />} />
							<Route exact path={routes.SOLUTIONS} component={() => <SolutionsPage />} />
							<Route path={routes.EXAMPLE + "/:esl"} component={() => <EventstormingPage />} />
							<Route path={routes.EDIT + "/:esid"} component={() => <EventstormingPage />} />
							<Route exact path={routes.EDIT} component={() => <EventstormingPage />} />
							<Route exact path={routes.PRICING} component={() => <PricingPage />} />
							<Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
						</Suspense>
					</div>
				</Router>
			</React.Fragment>
		</MuiThemeProvider>
	</ErrorBoundary>

export default
	compose(
		withAuthentication()
	)(App)