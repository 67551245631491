import React, { Component } from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import logo from "../../assets/storystream-logo.png";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Help from '../EventstormingPage/Help';
import Profile from "../Profile";
import { Link } from 'react-router-dom'
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';

const SearchPage = React.lazy(() => import('../SearchPage'));

class Navigation extends Component {
	constructor() {
		super()
		this.state = {
			anchorEl: null,
			helpMenuVisible: false,
			isHelpDialogOpen: false,
			isSearchOpen: false,
		}
	}

	actions = {
		handleSearchOpened: (event) => {
			this.setState({ isSearchOpen: true })
		},
		handleSearchClosed: () => { this.setState({ isSearchOpen: false }) },

		handleHelpOpened: (event) => {
			this.setState({ isHelpDialogOpen: true, anchorEl: event.currentTarget })
		},
		handleHelpClosed: () => { this.setState({ isHelpDialogOpen: false }) },

		toggleSearchOpen: () => { this.setState({ isSearchOpen: !this.state.isSearchOpen})}
	}

	render() {
		return (
			<React.Fragment>
				{this.props.authUser
					? <NavigationAuth
						authUser={this.props.authUser}
						isHelpDialogOpen={this.state.isHelpDialogOpen}
						isSearchOpen={this.state.isSearchOpen}
						anchorEl={this.state.anchorEl}
						{...this.actions}
					/>
					: <NavigationNonAuth />
				}
			</React.Fragment>
		)
	}
}
const NavigationAuth = (props) =>
	<React.Fragment>
		<AppBar position="static" color="primary" style={{ marginBottom: "1em" }}>
			<Toolbar style={{ width: "100%", display: "flex", alignContent: "center", }}>
				<Link to="/" style={{ color: "white", display: "block" }}>
					<img src={logo} alt={"StoryStream"} height="50" style={{ color: "white" }} />
				</Link>
				<div style={{ marginLeft: "auto" }}>
					<Tooltip title="Search">
						<IconButton
							onClick={props.handleSearchOpened}
							color="inherit"
							aria-haspopup={true}
							id="searchButton"
						>
							<div id="search-icon"><SearchIcon /></div>
						</IconButton>
					</Tooltip>
					<Tooltip title="Help">
						<IconButton
							onClick={props.handleHelpOpened}
							color="inherit"
							aria-owns={'help-menu'}
							aria-haspopup={true}
							id="helpButton"
						>
							<div id="help-icon"><HelpIcon /></div>
						</IconButton>
					</Tooltip>
					<Profile />
				</div>
			</Toolbar>
		</AppBar>
		<Help
			isOpen={props.isHelpDialogOpen}
			handleHelpClosed={props.handleHelpClosed}
			anchorEl={props.anchorEl}
		/>
		<SearchPage
			isOpen={props.isSearchOpen}
			toggleOpen={props.toggleSearchOpen}
		/>
	</React.Fragment>

const NavigationNonAuth = () =>
	<React.Fragment>
		<AppBar position="static" color="primary" style={{ marginBottom: "1em" }}>
			<Toolbar>
				<Grid container justify="space-between" alignItems="center" >
					<Grid item>
						<Grid container alignItems="center" spacing={32}>
							<Grid item>
								<a href="https://getstorystream.com"><img src={logo} alt={"StoryStream"} height="50" /></a>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	</React.Fragment>

const mapStateToProps = (state) => ({
	authUser: state.sessionState.authUser,
})

export default compose(
	withRouter,
	connect(mapStateToProps)
)(Navigation)
