// Import FirebaseAuth and firebase.
import { DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as routes from '../../constants/routes';
import { auth } from '../../firebase';
import Navigation from '../Navigation';
import Typography from '@material-ui/core/Typography'
import { openSnackbar } from '../Notifier';

class SignOut extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Navigation />
        <Dialog
          open={true}
        >
          <DialogContent>
            <Typography variant="caption" gutterBottom></Typography>
            <Typography variant="body2" onClick={this.displayCurrentVersion()}>Really log out?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.history.push(routes.SOLUTIONS)} >
              Cancel
            </Button>
            <Button onClick={this.signOut()} variant="contained" color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  signOut() {
    return async () => {
      await auth.doSignOut();
      sessionStorage.clear();
      this.props.history.push(routes.SOLUTIONS)
      openSnackbar({ message: "You have been logged out of StoryStream." })
    }
  }

  displayCurrentVersion() {
    return () => { openSnackbar({ message: "Version: " + process.env.REACT_APP___COMMIT_HASH__ + " " + process.env.REACT_APP___BUILD_DATETIME___ }); };
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(SignOut);
