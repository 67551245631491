import { EVENTSTORMING_UPDATED, EVENTSTORMING_CREATED, EVENTSTORMING_UNLOADED, EVENTSTORMING_DELETED, EDITOR_CURSOR_LINE_CHANGED, EDITOR_WORKFLOWS_UPDATED } from "../../constants/ActionTypes"
import EsToEsDiagram from "../Esl/EsToEsDiagram"
import { addToHistory } from '../../api/history/addToHistory';

export const initialEventstormingState = {
	id: null,
	name: "Untitled",
	editorText: "",
	role: "",
	esl: [],
	linePosition: null,
	currentWorkflow: null,
	workflowLines: []
}

const refreshDiagram = (eventstorming) => {
	if (!eventstorming.editorText) {
		return eventstorming
	}
	if (eventstorming.editorText) {
		const esToEsDiagram = new EsToEsDiagram()
		let result = esToEsDiagram.buildFrom(eventstorming.editorText)
		let stateWithParserResults = { ...{ ...eventstorming, esl: result } }
		return stateWithParserResults
	}
}

const applyEventstormingUpdated = (state, action) => {
	const newState = { ...{ ...state, ...action.solution } }
	return refreshDiagram(newState)
}


const applyCreateEventstorming = (state, action) => {
	const newState = { ...initialEventstormingState, id: action.eventstorming.id, name: action.eventstorming.name, role: "admin", editorText: `/// ${action.eventstorming.name}\n\n` }
	return (newState)
}

const applyEventstormingUnloaded = (state, action) => {
	if (state) {
		const thisIsntOneOfTheEventstormingSamples = state.id;
		if (thisIsntOneOfTheEventstormingSamples) {
			const historyEvent = {
				type: EVENTSTORMING_UPDATED,
				id: state.id,
				editorText: state.editorText,
				name: state.name,
				role: state.role
			}
			addToHistory(action.authUserUid, historyEvent);
		}
	}
	return initialEventstormingState
}

const applyEventstormingDeleted = (state, action) => {
	if (state) {
		const thisIsntOneOfTheEventstormingSamples = state.id;
		if (thisIsntOneOfTheEventstormingSamples) {
			const historyEvent = {
				type: EVENTSTORMING_DELETED,
				id: state.id,
				editorText: state.editorText,
				name: state.name,
				role: state.role
			}
			addToHistory(action.authUserUid, historyEvent);
		}
	}
	return initialEventstormingState
}

const applyEditorCursorLineChanged = (state, action) => {
	let newState = { ...state, ...action.data }
	const currentWorkflow = state.workflowLines.filter(item => (
		item.line <= action.data.linePosition
	))
	if (currentWorkflow.length === 0) {
		newState.currentWorkflow = null
	} else {
		newState.currentWorkflow = currentWorkflow[currentWorkflow.length - 1].id
	}
	return newState
}

const applyUpdateEditorWorkflows = (state, action) => {
	let newState = { ...state }
	newState.workflowLines = action.data.workflowLines
	return newState
}

export function eventstormingReducer(state = initialEventstormingState, action) {
	switch (action.type) {
		case EVENTSTORMING_CREATED: {
			return applyCreateEventstorming(state, action)
		}
		case EVENTSTORMING_UPDATED: {
			return applyEventstormingUpdated(state, action)
		}
		case EVENTSTORMING_UNLOADED: {
			return applyEventstormingUnloaded(state, action)
		}		
		case EVENTSTORMING_DELETED: {
			return applyEventstormingDeleted(state, action)
		}
		case EDITOR_CURSOR_LINE_CHANGED: {
			return applyEditorCursorLineChanged(state, action)
		}
		case EDITOR_WORKFLOWS_UPDATED: {
			return applyUpdateEditorWorkflows(state, action)
		}
		default:
			return state
	}
}
