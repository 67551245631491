import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { Component } from 'react';
import EventstormingEditorTour from '../Eventstorming/EventstormingEditorTour';


class Help extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditorTourOpen: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Menu id="help-menu"
                    open={this.props.isOpen}
                    onClose={this.props.handleHelpClosed}
                    anchorEl={this.props.anchorEl}
                >
                    {/* <MenuItem onClick={() => { 
                        this.setState({ isEditorTourOpen: true }) 
                        this.props.handleHelpClosed()
                        }}>Tour</MenuItem> */}
                    <MenuItem onClick={() => window.location.href = "https://getstorystream.com/blog/esl-introduction/"}>Intro</MenuItem>
                    <MenuItem onClick={() => window.location.href = "https://getstorystream.com/blog/esl-bikeshare-example/"}>Case Study</MenuItem>
                </Menu>
                <EventstormingEditorTour run={this.state.isEditorTourOpen} toggleEslEditorTourOpen={ () => {this.setState({ isEditorTourOpen: !this.state.isEditorTourOpen }) }} />
            </React.Fragment>
        )
    }
}

Help.propTypes = {
    handleHelpClosed: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
};

export default Help