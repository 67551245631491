import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import "./index.css"
import App from "./features/App"
import store from "./store"
import registerServiceWorker from "./registerServiceWorker"
import { unregister } from "./registerServiceWorker";

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
)

registerServiceWorker()

// According to https://stackoverflow.com/questions/50598614/react-app-on-firebase-new-deployment-not-showing-old-deployment-cached-in-brow, 
// this should disable caching so that new deployments show up without users re-loading the page:
unregister()