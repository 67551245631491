import React, { Component } from "react";

class ErrorBoundary extends Component {
	constructor() {
		super()
		this.state = {
			hasError: false
		}
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true })
	}

	render() {
		if(this.state.hasError) {
			return(<React.Fragment>An Error Has Occurred.</React.Fragment>)
		} else {
			return this.props.children
		}
	}
}

export default ErrorBoundary

