import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import ErrorBoundary from '../ErrorBoundary';
import LoadingSpinner from "../LoadingSpinner";

class Profile extends Component {
    state = {
        anchorEl: null,
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    logout = () => this.props.auth.logout()

    render() {
        const { anchorEl } = this.state;
        return (
            <ErrorBoundary>
                {!this.props.authUser ? <LoadingSpinner /> :  <React.Fragment />}
                <div style={!this.props.authUser ? { display: "none" } : { display: "block" }}>                
                <React.Fragment>
                    <IconButton
                        onClick={this.handleClick}
                        color="inherit"
                        aria-owns={anchorEl ? 'simple-menu' : null}
                        aria-haspopup={true}
                        style={{ color: '#ffffff' }}
                    >
                        <Avatar src={this.props.authUser.photoURL} alt="profile" />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem><Link to={routes.ACCOUNT}>Account</Link></MenuItem>
                        {this.props.subscription && this.props.subscription.isActive && this.props.subscription.amount ? null :
                            <MenuItem><Link to={routes.PRICING}>Subscribe</Link></MenuItem>}
                        <MenuItem><Link to={routes.SIGN_OUT}>Logout</Link></MenuItem>
                    </Menu>
                </React.Fragment>
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    subscription: state.subscriptionState.subscription
});

export default compose(
    connect(mapStateToProps))(Profile);
