import { combineReducers } from "redux"
import sessionReducer, { initialSessionState } from "./session"
import userReducer, { initialUserState } from "./user"
import subscriptionReducer, { initialSubscriptionState } from "../features/Subscription/SubscriptionReducer"
import { eventstormingReducer, initialEventstormingState } from "../features/Eventstorming/EventstormingReducer"
import { workspaceReducer, initialWorkspaceState } from "../features/Workspaces/WorkspacesReducer"
import { searchIndexReducer, initialSearchIndexState } from "../features/Workspaces/SearchIndexReducer"
import { editorsReducer, initialEditorsState } from "../features/EditorList/EditorsReducer"

import { ACCOUNT_DELETED } from "../constants/ActionTypes"

const appReducer = combineReducers({
	sessionState: sessionReducer,
	userState: userReducer,
	subscriptionState: subscriptionReducer,
	eventstormingState: eventstormingReducer,
	workspaceState: workspaceReducer,
	searchIndexState: searchIndexReducer,
	editorsState: editorsReducer,
})

const rootReducer = (state, action) => {
	if (action.type === ACCOUNT_DELETED) {
		state = undefined
	}

	return appReducer(state, action)
}

export const INITIAL_STATE = {
	sessionState:  initialSessionState ,
	userState: initialUserState,
	subscriptionState: initialSubscriptionState,
	eventstormingState: initialEventstormingState,
	workspaceState: initialWorkspaceState,
	searchIndexState: initialSearchIndexState,
	editorsState: initialEditorsState,
}

export default rootReducer
