import { firestore, firebase } from '../../firebase/firebase';
import uuidv4 from 'uuid/v4';

export function addToHistory(uid, event) {
  const historyCollection = firestore.collection(`users/${uid}/history`);
  const eventId = uuidv4()
  const timeStamp = firebase.firestore.FieldValue.serverTimestamp()
  historyCollection.doc(eventId)
    .set({
      eventId: eventId,
      timeStamp: timeStamp,
      uid: uid,
      ...event
    })
    .catch(function (error) {
      console.error("Error updating document: ", error);
    });
}
