/* eslint-disable indent */

export function parse(esl) {
  if (!esl) {
    return {}
  }

  let workflowFinderRegex = /^ *\/\/\/ *(.*) *$/g
  let workflowMatch = workflowFinderRegex.exec(esl)
  if (workflowMatch) {
    let workflowName = null
    if (workflowMatch[1]) {
      workflowName = workflowMatch[1]
    } else {
      return null // workflows must have a name
    }
    const workflow = {
      type: "workflow",
      text: workflowName,
    }
    return workflow
  }

  let commentFinderRegex = /^ *\/\/ *(.*) *$/g
  let commentMatch = commentFinderRegex.exec(esl)
  if (commentMatch) {
    let commentText = null
    if (commentMatch[1]) {
      commentText = commentMatch[1]
    } else {
      return null // comments must have text
    }
    const comment = {
      type: "comment",
      text: commentText,
    }
    return comment
  }

  let eslStatementType = "event"

  let commandFinderRegex = /^(.*)(->) *:* *(.*)$/g
  let documentFinderRegex = /^(.*)(\*) *:* *(.*)$/g
  let match = null
  match = commandFinderRegex.exec(esl)
  if (!match) {
    match = documentFinderRegex.exec(esl)
    if (match) {
      eslStatementType = "document"
    }
  } else {
    eslStatementType = "command"
  }
  if (match) {
    let eslStatementName = eslStatementNameFrom(match)

    let parameters = []
    let paramList = null
    if (match[3]) {
      // It's a command or a document
      paramList = match[3].trim().split(",")
    }
    parameters = parametersFrom(paramList)
    return {
      type: eslStatementType,
      name: eslStatementName,
      parameters: parameters
    }
  } else {

    // None of the above, so it's an event
    let paramList = []
    // ':' as a first character in an event is not valid:
    while (esl.charAt(0) === ':') {
      esl = esl.substr(1);
    }
    if(!esl) {
      return null
    }
    let eslName = esl.split(":")[0]
    if (!eslName) {
      eslName = null
    } else {
      eslName = eslName.trim()
    }
    let parameters = esl.split(":")[1]
    if (parameters) {
      paramList = parameters.trim().split(",")
    }
    parameters = parametersFrom(paramList)
    const event = {
      type: "event",
      name: eslName,
      parameters: parameters
    }
    return event
  }
}

function eslStatementNameFrom(match) {
  if (!match[1]) {
    return null
  }
  return match[1].trim()
}

function parametersFrom(paramList) {
  let result = []
  if (!paramList) {
    return result
  }

  paramList.forEach((param) => {
    let nameAndExample = param.trim().split("=")
    let nameAndExampleResult = {}

    let name = nameAndExample[0] ? nameAndExample[0].trim() : null
    if (name) {
      nameAndExampleResult = { ...nameAndExampleResult, name: name }
    }

    let example = nameAndExample[1] ? nameAndExample[1].trim() : null
    if (example) {
      example = example.trim()
    }
    if (example) {
      nameAndExampleResult = { ...nameAndExampleResult, example: example }
    }

    // VALIDATE: Parameters must have names.
    if (nameAndExampleResult.name) {
      result = [...result, nameAndExampleResult]
    }
  })
  return result
}
