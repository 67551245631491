import React from "react"
import { connect } from "react-redux"
import { firebase } from "../../firebase"
import LoadingSpinner from "../LoadingSpinner"
import { GoogleAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();

const withAuthentication = () => (Component) => {
	class WithAuthentication extends React.Component {
		constructor() {
			super()
			this.state = {
				isLoading: false
			}
		}

		componentDidMount() {
			this.setState({ isLoading: true })
			const { onSetAuthUser } = this.props
			if (sessionStorage.getItem("authredirect") !== "true") {
				firebase.auth
					.signInWithRedirect(provider)
				firebase.auth
					.getRedirectResult()
					.then((result) => {
						sessionStorage.setItem("authredirect", "true");
						var authUser = result.user;
						if (authUser) {
							onSetAuthUser(authUser)
						}
					}).catch((error) => {
						var errorCode = error.code;
						var errorMessage = error.message;
						console.log(`Error ${errorCode}: ${errorMessage}`)
					});
			}
			firebase.auth.onAuthStateChanged(authUser => {
				this.setState({ isLoading: true })
				sessionStorage.removeItem("authredirect");
				if(authUser) {
					onSetAuthUser(authUser)
				} else {
					onSetAuthUser(null)					
				}
				this.setState({ isLoading: false })
			})
			this.setState({ isLoading: false })
		}

		render() {
			return (
				<React.Fragment>
					{this.state.isLoading ? <LoadingSpinner /> : null}
					<div style={this.state.isLoading ? { display: "none" } : { display: "block" }}>
						<Component />
					</div>
				</React.Fragment>
			)
		}
	}

	const mapDispatchToProps = (dispatch) => ({
		onSetAuthUser: (authUser) => dispatch({ type: "AUTH_USER_SET", authUser }),
	})

	const mapStateToProps = (state) => ({
		authUser: state.sessionState ? state.sessionState.authUser : null
	})

	return connect(mapStateToProps, mapDispatchToProps)(WithAuthentication)

}

export default withAuthentication