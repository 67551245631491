import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Component, default as React, } from "react";

class LoadingSpinner extends Component {
	render() {
		return (
			<Grid
				container
				alignItems="center"
				alignContent="stretch"
				style={{ flexGrow: 1, height: "100%" }}

			>
				<Grid item style={{ marginLeft: "auto", marginRight: "auto", padding: "20px" }}>
					<CircularProgress />
				</Grid>
			</Grid>
		)
	}
}

export default LoadingSpinner



