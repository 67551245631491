import { SUBSCRIPTION_UPDATED } from '../../constants/ActionTypes';
import { SUBSCRIPTION_DELETED } from '../../constants/ActionTypes'

export const initialSubscriptionState = {
  subscription: null
};

const applyUpdateSubscription = (state, action) => {
  return { ...state, subscription: action.subscription }
}

const applyDeleteSubscription = (state, action) => { 
  return { subscription: null }
};

function subscriptionReducer(state = initialSubscriptionState, action) {
  switch (action.type) {
    case SUBSCRIPTION_UPDATED: {
      return applyUpdateSubscription(state, action);
    }
    case SUBSCRIPTION_DELETED : {
      return applyDeleteSubscription(state, action);
    }
    default: return state;
  }
}

export default subscriptionReducer;
