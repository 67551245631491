import { createMuiTheme } from "@material-ui/core/styles";
export const theme = createMuiTheme({
	typography: {
        useNextVariants: true,
	},
	palette: {
		type: "light",
		primary: {
			main: "#2F2F4C",
			light: "#6a99c8",
		},
		secondary: { main: "#e96667" },
	},
});
