import { buildEslDiagram } from '../EslDiagram/buildEslDiagram'
import { parse } from "./parse"

class EsToEsDiagram {
    // Eventstorming

    whiteboard = []

    solutionName = null

    currentStreamId = null

    buildFrom(input) {
        let lineNo = 1
        const eslLines = input.split("\n")
        eslLines.forEach((line) => {
            let eslStatement = parse(line)
            if (eslStatement) {
                switch (eslStatement.type) {
                    case "workflow":
                        this.addWorkflow(eslStatement, lineNo)
                        break
                    case "command":
                        this.addCommand(eslStatement, lineNo)
                        break
                    case "event":
                        this.addEvent(eslStatement, lineNo)
                        break
                    case "document":
                        this.addDocument(eslStatement, lineNo)
                        break
                    case "comment":
                        this.addComment(eslStatement, lineNo)
                        break
                    default:
                        break;
                }
            }
            lineNo++
        })
        return this.whiteboard
    }


    // Commands
    addCommand(command, lineNo) {
        if (command.name && command.name.trim() !== "") {
            let commandTemplate = {
                id: null,
                type: "command",
                name: null,
                parameters: [],
                lineNo: null
            }
            commandTemplate.id = makeKeyFrom(command.name)
            commandTemplate.lineNo = lineNo
            commandTemplate = { ...commandTemplate, ...command }
            if (commandTemplate.name) {
                this.whiteboard = buildEslDiagram(this.whiteboard, commandTemplate)
            }
        }
    };

    // Events
    addEvent(event, lineNo) {
        if (event.name && event.name.trim() !== "") {
            let eventTemplate = {
                id: null,
                name: null,
                type: "event",
                properties: [],
                lineNo: null
            }
            eventTemplate.id = makeKeyFrom(event.name)
            eventTemplate.lineNo = lineNo
            eventTemplate.name = event.name
            eventTemplate.properties = event.parameters // TODO: Remove the context map. It should be event.properties - same language.
            if (eventTemplate.name) {
                this.whiteboard = buildEslDiagram(this.whiteboard, eventTemplate)
            }
        }
    };

    // Documents
    addDocument(document, lineNo) {
        if (document.name && document.name.trim() !== "") {
            let documentTemplate = {
                id: null,
                name: null,
                type: "document",
                properties: [],
                lineNo: null
            }
            documentTemplate.id = makeKeyFrom(document.name)
            documentTemplate.type = "document"
            documentTemplate.lineNo = lineNo
            documentTemplate.name = document.name
            documentTemplate.properties = document.parameters // TODO: Remove the context map. It should be document.properties - same language.
            this.whiteboard = buildEslDiagram(this.whiteboard, documentTemplate)
        }
    };

    // Comments 
    addComment = function (comment, lineNO) {
        if (comment.text && comment.text.trim() !== "") {
            let commentTemplate = {
                text: "",
                type: "comment",
                lineNo: null
            }
            commentTemplate.id = makeKeyFrom(comment.text)
            commentTemplate.text = comment.text
            commentTemplate.type = "comment"
            commentTemplate.lineNo = lineNO
            this.whiteboard = buildEslDiagram(this.whiteboard, commentTemplate)
        }
    };

    // Workflows 
    workflowLines = []

    addWorkflow = function (workflow, lineNo) {
        let workflowTemplate = {
            id: null,
            text: "",
            type: "workflow",
            lineNo: null
        }
        workflowTemplate.id = makeKeyFrom(workflow.text)
        workflowTemplate.lineNo = lineNo
        workflowTemplate.type = "workflow"
        workflowTemplate.text = workflow.text
        this.whiteboard = buildEslDiagram(this.whiteboard, workflowTemplate)
        // Ensure that the first workflow found is used as the name for the event storming solution under which it's saved:
        if (!this.solutionName) {
            this.solutionName = workflowTemplate.text.trim()
        }
        this.workflowLines.push({ line: workflowTemplate.lineNo, id: workflowTemplate.id })
    };

};

const makeKeyFrom = (name) => (replaceAll(name.toLowerCase(), " ", ""))

const replaceAll = function (inputString, search, replacement) {
    return inputString.split(search).join(replacement);
};

export default EsToEsDiagram