export const SUBSCRIPTION_UPDATED = "SUBSCRIPTION_UPDATED"
export const SUBSCRIPTION_DELETED = "SUBSCRIPTION_DELETED"

export const EVENTSTORMING_CREATED = "EVENTSTORMING_CREATED"
export const EVENTSTORMING_UPDATED = "EVENTSTORMING_UPDATED"
export const EVENTSTORMING_UNLOADED = "EVENTSTORMING_UNLOADED"
export const EVENTSTORMING_DELETED = "EVENTSTORMING_DELETED"
export const EVENTSTORMING_EDITOR_ADDED = "EVENTSTORMING_EDITOR_ADDED"
export const EVENTSTORMING_EDITOR_DELETED = "EVENTSTORMING_EDITOR_DELETED"
export const EVENTSTORMING_EDITORLIST_CLEARED = "EVENTSTORMING_EDITORLIST_CLEARED"

export const EDITOR_CURSOR_LINE_CHANGED = "EDITOR_CURSOR_LINE_CHANGED"
export const EDITOR_WORKFLOWS_UPDATED = "EDITOR_WORKFLOWS_UPDATED"

export const ACCOUNT_DELETED = "ACCOUNT_DELETED"

export const WORKSPACE_EVENTSTORMING_ADDED = "WORKSPACE_EVENTSTORMING_ADDED"
export const WORKSPACE_EVENTSTORMING_UPDATED = "WORKSPACE_EVENTSTORMING_UPDATED"
export const WORKSPACE_EVENTSTORMING_DELETED = "WORKSPACE_EVENTSTORMING_DELETED"

export const WORKSPACE_SEARCHINDEX_ADDED = "WORKSPACE_SEARCHINDEX_ADDED"
export const WORKSPACE_SEARCHINDEX_UPDATED = "WORKSPACE_SEARCHINDEX_UPDATED"
export const WORKSPACE_SEARCHINDEX_DELETED = "WORKSPACE_SEARCHINDEX_DELETED"

export const LOADING_STARTED = "LOADING_STARTED"
export const LOADING_FINISHED = "LOADING_FINISHED"
