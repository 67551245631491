import { WORKSPACE_SEARCHINDEX_ADDED } from "../../constants/ActionTypes"
import { WORKSPACE_SEARCHINDEX_UPDATED } from "../../constants/ActionTypes"
import { WORKSPACE_SEARCHINDEX_DELETED } from "../../constants/ActionTypes"
import lunr from "lunr";
import * as key from '../../constants/localStorage';

export const initialSearchIndexState = { documents: [], index: null }

const applyAddSearchIndex = (state = initialSearchIndexState, action) => {
	let alreadyContainsTheNewEventstorming = false
	state.documents.forEach(eventstorming => {
		if (eventstorming.esId === action.eventstorming.esId) {
			alreadyContainsTheNewEventstorming = true
		}
	})
	if (state.documents) {
		let idx = lunr(buildIndex(state.documents))
		saveIndex(idx);
		
		if (!alreadyContainsTheNewEventstorming) {
			return { documents: [...state.documents, action.eventstorming], index: idx }
		}
		return { documents: [...state.documents], index: idx }
	}
	return { documents: [...state.documents], index: null }
}

const applyUpdateSearchIndex = (state = initialSearchIndexState, action) => {
	if (!action.eventstorming.esId) {
		throw new Error("action.eventstorming.esId must not be null/undefined")
	}
	const newState = []
	state.documents.forEach(eventstorming => {
		if (eventstorming.esId === action.eventstorming.esId) {
			newState.push(action.eventstorming)
		} else {
			newState.push(eventstorming)
		}
	})
	if (newState) {
		let idx = lunr(buildIndex(newState))
		saveIndex(idx);
		return { documents: [...newState], index: idx }
	}
	return { documents: [...newState], index: null }
}

const applyDeleteSearchIndex = (state, action) => {
	if (!action.id) {
		return state
	}
	const newState = []
	state.documents.forEach((eventstorming) => {
		if (eventstorming.esId !== action.id) {
			newState.push(eventstorming)
		}
	})
	return { documents: [...newState], index: state.index }
}

function saveIndex(idx) {
	localStorage.setItem(key.SEARCH_INDEX, JSON.stringify(idx));
}

function buildIndex(documents) {
	return function () {
		this.ref('esId');
		this.field('name');
		this.field('shared');
		this.field('mine');
		this.metadataWhitelist = ['position'];
		documents.forEach(function (doc) {
			this.add(doc);
		}, this);
	};
}

export function searchIndexReducer(state = initialSearchIndexState, action) {
	switch (action.type) {
		case WORKSPACE_SEARCHINDEX_ADDED: {
			return applyAddSearchIndex(state, action)
		}
		case WORKSPACE_SEARCHINDEX_UPDATED: {
			return applyUpdateSearchIndex(state, action)
		}
		case WORKSPACE_SEARCHINDEX_DELETED: {
			return applyDeleteSearchIndex(state, action)
		}
		default: return state
	}
}
