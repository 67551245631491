import { WORKSPACE_EVENTSTORMING_ADDED } from "../../constants/ActionTypes"
import { WORKSPACE_EVENTSTORMING_UPDATED } from "../../constants/ActionTypes"
import { WORKSPACE_EVENTSTORMING_DELETED } from "../../constants/ActionTypes"

export const initialWorkspaceState = []

const applyAddEventstorming = (state = initialWorkspaceState, action) => {
	let alreadyContainsTheNewEventstorming = false
	state.forEach(eventstorming => {
		if (eventstorming.id === action.eventstorming.id) {
			alreadyContainsTheNewEventstorming = true
		}
	})
	if (!alreadyContainsTheNewEventstorming) {
		return [...state, action.eventstorming]
	}
	return [...state]
}

const applyUpdateEventstorming = (state = initialWorkspaceState, action) => {
	if(!action.eventstorming.id) {
		throw new Error("action.eventstorming.id must not be null/undefined")
	}
	const newState = []
	state.forEach(eventstorming => {
		if (eventstorming.id === action.eventstorming.id) {
			newState.push(action.eventstorming)
		} else {
			newState.push(eventstorming)
		}
	})
	return newState
}

const applyDeleteEventstorming = (state, action) => {
	if(!action.id) {
		return state
	}
	const newState = []
	state.forEach((eventstorming) => {
		if (eventstorming.id !== action.id) {
			newState.push(eventstorming)
		}
	})
	return newState
}

export function workspaceReducer(state = initialWorkspaceState, action) {
	switch (action.type) {
	case WORKSPACE_EVENTSTORMING_ADDED: {
		return applyAddEventstorming(state, action)
	}
	case WORKSPACE_EVENTSTORMING_UPDATED: {
		return applyUpdateEventstorming(state, action)
	}
	case WORKSPACE_EVENTSTORMING_DELETED: {
		return applyDeleteEventstorming(state, action)
	}
	default: return state
	}
}
