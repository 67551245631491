import { EVENTSTORMING_EDITOR_ADDED, EVENTSTORMING_EDITOR_DELETED, EVENTSTORMING_EDITORLIST_CLEARED } from "../../constants/ActionTypes"

export const initialEditorsState = []

const applyEditorAdded = (state, action) => {
	let alreadyContainsEditor = false
	state.forEach(existingEditor => {
		if (existingEditor.uid === action.data.uid) {
			alreadyContainsEditor = true
		}
	})
	if (!alreadyContainsEditor) {
		return [...state, action.data]
	} else {
		return [...state]
	}
}

const applyEditorDeleted = (state, action) => {
	const newState = state.filter(item => item.uid !== action.data.uid)
	return newState
}

const applyEditorListCleared = () => {
	const newState = initialEditorsState
	return newState
}

export function editorsReducer(state = initialEditorsState, action) {
	switch (action.type) {
	case EVENTSTORMING_EDITOR_ADDED: {
		return applyEditorAdded(state, action)
	}
	case EVENTSTORMING_EDITOR_DELETED: {
		return applyEditorDeleted(state, action)
	}
	case EVENTSTORMING_EDITORLIST_CLEARED: {
		return applyEditorListCleared()
	}
	default: return state
	}
}
