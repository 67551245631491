import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/auth"
import "firebase/compat/functions"
import "firebase/compat/database"

const prodConfig = {
	apiKey: "AIzaSyD71M2nxUws1x_ugDu51Od2yGiSm_-MVJI",
	authDomain: "app.getstorystream.com",
	databaseURL: "https://webeventstorming-332b1.firebaseio.com",
	projectId: "webeventstorming-332b1",
	storageBucket: "",
	messagingSenderId: "868949705173",
}

const devConfig = {
	apiKey: " AIzaSyAKNovA8BZB4BE35i5F_xhd1e8sUfZ7H-8",
	authDomain: "storystream-dev.firebaseapp.com",
	databaseURL: "https://storystream-dev.firebaseio.com",
	projectId: "storystream-dev",
	storageBucket: "",
	messagingSenderId: "386428109787",
}

// eslint-disable-next-line no-undef
let config = process.env.NODE_ENV === "production"
	? prodConfig
	: devConfig

// This is necessary in order to be able to deploy to storystream-dev.firebaseapp.com because the NODE_ENV for it will be set to "production". As we don't want the QA environment to use the production settings, this is a workaround.
// eslint-disable-next-line no-undef
if(process.env.REACT_APP_QA_ENVIRONMENT === "storystream-dev") {
	config = devConfig
}

if (!firebase.apps.length) {
	firebase.initializeApp(config)
}

const db = firebase.database()
const firestore = firebase.firestore();
const auth = firebase.auth()
const functions = firebase.functions()

export {
	db,
	firestore,
	firebase,
	auth,
	functions
}
