const WORKFLOWROW = 0
const COMMANDROW = 1
const EVENTROW = 2
const DOCUMENTROW = 3
const FIRSTROWWITHCOMMENT = 4

export const buildEslDiagram = (whiteboard, postIt) => {
	let row = 0
	let currentColumn = 0
	if (whiteboard.length > 0) {
		currentColumn = whiteboard[row].length - 1
	}
	switch (postIt.type) {
	case "workflow":
		row = WORKFLOWROW
		if (columnAlreadyContainsAnItemInAnyRow(whiteboard, currentColumn)) {
			currentColumn = addANewColumn(currentColumn)
		}
		break
	case "command":
		row = COMMANDROW
		if (columnAlreadyContainsACommandOrEventOrComments(whiteboard, currentColumn)) {
			currentColumn = addANewColumn(currentColumn)
		}
		break
	case "event":
		row = EVENTROW
		if (columnAlreadyContainsAnItemInRow(whiteboard, row, currentColumn)) {
			currentColumn = addANewColumn(currentColumn)
		}
		break
	case "document":
		row = DOCUMENTROW
		if (columnAlreadyContainsAnItemInRow(whiteboard, row, currentColumn)) {
			currentColumn = addANewColumn(currentColumn)
		}
		break
	case "comment":
		row = FIRSTROWWITHCOMMENT
		if (thereIsAlreadyACommentInTheLastRowOf(whiteboard)) {
			if (columnAlreadyContainsAnItemInRow(whiteboard, row, currentColumn)) {
				row = addAnotherCommentBelow(row, whiteboard)
			} else {
				row = addFirstComment(row)
			}
		}
		else {
			row = addFirstComment(row)
		}
		break
	default:
	}

	let emptyArrayWithAllRequiredRowsAndColumns = createEmptyArraySizedFor(whiteboard.length, row, currentColumn)

	let result = copyItemsTo(emptyArrayWithAllRequiredRowsAndColumns, whiteboard)
	result[row][currentColumn] = postIt
	return result
}

function createEmptyArraySizedFor(whiteboardLength, row, currentColumn) {
	let noOfRows = whiteboardLength
	if (whiteboardLength < row + 1) {
		noOfRows = row + 1
	}
	let emptyArrayWithAllRequiredRowsAndColumns = new Array(noOfRows).fill(null)
	for (let cnt = 0; cnt <= noOfRows - 1; cnt++) {
		emptyArrayWithAllRequiredRowsAndColumns[cnt] = new Array(currentColumn + 1).fill(null)
	}
	return emptyArrayWithAllRequiredRowsAndColumns
}

function copyItemsTo(emptyArrayWithAllRequiredRowsAndColumns, whiteboard) {
	let result = Object.assign([], emptyArrayWithAllRequiredRowsAndColumns)
	for (let rowCnt = 0; rowCnt < result.length; rowCnt++) {
		if (whiteboard[rowCnt]) {
			for (let colCnt = 0; colCnt < whiteboard[rowCnt].length; colCnt++) {
				if (whiteboard[rowCnt][colCnt]) {
					result[rowCnt][colCnt] = whiteboard[rowCnt][colCnt]
				}
			}
		}
	}
	return result
}

function addANewColumn(currentColumn) {
	currentColumn++
	return currentColumn
}



function columnAlreadyContainsAnItemInRow(whiteboard, row, currentColumn) {
	return whiteboard[row] && whiteboard[row][currentColumn]
}

function columnAlreadyContainsAnItemInAnyRow(whiteboard, column) {
	for (let cnt = 0; cnt < whiteboard.length; cnt++) {
		if (whiteboard[cnt][column]) {
			return true
		}
	}
	return false
}

function columnAlreadyContainsACommandOrEventOrComments(whiteboard, column) {
	for (let cnt = COMMANDROW; cnt < whiteboard.length; cnt++) {
		if (whiteboard[cnt][column]) {
			return true
		}
	}
	return false
}

function addFirstComment(row) {
	row = FIRSTROWWITHCOMMENT
	return row
}

function addAnotherCommentBelow(row, whiteboard) {
	row = whiteboard.length
	return row
}

function thereIsAlreadyACommentInTheLastRowOf(whiteboard) {
	return whiteboard.length - 1 >= FIRSTROWWITHCOMMENT
}

