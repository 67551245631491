// import firebase from "firebase/compat/app";
// import "firebase/compat/database";
import { firebase } from './../firebase/firebase';


// Subscription API
export const setSubscription = (userUid, planId) => {
	firebase.database().ref(`/stripe_customers/${userUid}/subscriptions`).push({ planId: planId })
}

export const onSubscriptionStatusChange = (userUid, handleQueryResult) => {
	firebase.database().ref(`/stripe_customers/${userUid}/subscriptions`).on("value", (snapshot) => {
		snapshot.forEach(childSnapshot => {
			let key = childSnapshot.key
			let data = childSnapshot.val()
			if (data.error) {
				handleQueryResult({
					dbKey: key,
					isActive: false,
					error: data.error,
					subscriptionId: data.id,
					amount: data.plan ? data.plan.amount : null,
					currency: data.plan ? data.plan.currency : null,
					interval: data.plan ? data.plan.interval : null,
					nickname: data.plan ? data.plan.nickname : null
				})
			} else {
				handleQueryResult({
					dbKey: key,
					isActive: data.plan ? data.plan.active : false,
					error: null,
					subscriptionId: data.id,
					amount: data.plan ? data.plan.amount : null,
					interval: data.plan ? data.plan.interval : null,
					currency: data.plan ? data.plan.currency : null,
					nickname: data.plan ? data.plan.nickname : null
				})
			}
		})
	})
}

export const submitNewCreditCard = (uid, token) => {
	firebase.database().ref(`/stripe_customers/${uid}/sources`).push({ token: token })
}

export const getSharedEditUsers = (eventstormingId, handleQueryResult) => {
	firebase.database().ref(`/eventstorming/${eventstormingId}/users`).once("value").then((snapshot) => {
		let result = []
		snapshot.forEach(childSnapshot => {
			let key = childSnapshot.key
			let data = childSnapshot.val()
			result.push({ uid: key, ...data })
		})
		handleQueryResult(result)
	})
}
export const getEditors = async (eventstormingId) => {
	try {
		if (!eventstormingId) {
			return []
		}
		const snapshot = await firebase.database().ref(`/eventstorming/${eventstormingId}/users`).once("value")
		let result = []
		snapshot.forEach(childSnapshot => {
			let key = childSnapshot.key
			let data = childSnapshot.val()
			result.push({ uid: key, ...data })
		})
		return result
	} catch (error) {
		return error
	}
}

export const onEditorAdded = async (eventstormingId, onEditorAddedHandler) => {
	let sharedEditUsersDbRef = firebase.database().ref("/eventstorming/" + eventstormingId + "/users")
	sharedEditUsersDbRef.on("child_added", function (snapshot) {
		let data = snapshot.val()
		let key = snapshot.key
		onEditorAddedHandler({ uid: key, ...data })
	})
}


// Eventstorming

export const saveEventstorming = async (eventstorming) => {
	// don't overwrite the role...
	const eventstormingWithoutRole = { ...eventstorming }
	delete eventstormingWithoutRole.role
	const result = await firebase.database().ref(`/eventstorming/${eventstorming.id}/state`).set(eventstormingWithoutRole)
	return result
}

export const getWorkspace = async (workspaceId) => {
	let workspace = await firebase.database().ref(`/workspaces/${workspaceId}/stories`).once("value")
	let result = []
	workspace.forEach(eventstorming => {
		let key = eventstorming.key
		let data = eventstorming.val()
		result.push({ uid: key, ...data })
	})
	return result
}

export const deleteEventstormingFromWorkspace = async (workspaceId, eventstormingId) => {
	await firebase.database().ref(`/workspaces/${workspaceId}/stories/${eventstormingId}`).remove()
}

// For now, workspaceId is assumed to be the user UID: One workspace per user
export const onEventstormingAddedToWorkspace = async (workspaceId, handleQueryResult) => {
	try {
		firebase.database().ref(`/workspaces/${workspaceId}/stories`).on("child_added", async (snapshot) => {
			let key = snapshot.key
			let data = snapshot.val()
			await handleQueryResult({ id: key, ...data })
		})
	} catch (error) {
		console.log("ERROR - onEventstormingAddedToWorkspace:")
		console.log(error)
	}
}

// For now, workspaceId is assumed to be the user UID: One workspace per user
export const onWorkspaceEventstormingUpdated = async (workspaceId, handleQueryResult) => {
	try {
		firebase.database().ref(`/workspaces/${workspaceId}/stories`).on("child_changed", async (snapshot) => {
			let key = snapshot.key
			let data = snapshot.val()
			await handleQueryResult({ id: key, ...data })
		})
	} catch (error) {
		console.log("ERROR - onWorkspaceEventstormingUpdated:")
		console.log(error)
	}
}

export const onSearchIndexItemAdded = async (workspaceId, handleQueryResult) => {
	try {
		firebase.database().ref(`/workspaces/${workspaceId}/searchindex`).on("child_added", async (snapshot) => {
			let key = snapshot.key
			let data = snapshot.val()
			await handleQueryResult({ esId: key, ...data })
		})
	} catch (error) {
		console.log("ERROR - onEventstormingAddedToWorkspace:")
		console.log(error)
	}
}

export const onSearchIndexItemUpdated = async (workspaceId, handleQueryResult) => {
	try {
		firebase.database().ref(`/workspaces/${workspaceId}/searchindex`).on("child_changed", async (snapshot) => {
			let key = snapshot.key
			let data = snapshot.val()
			await handleQueryResult({ id: key, ...data })
		})
	} catch (error) {
		console.log("ERROR - onWorkspaceEventstormingUpdated:")
		console.log(error)
	}
}

export const onSearchIndexItemDeleted = async (workspaceId, handleQueryResult) => {
	try {
		firebase.database().ref(`/workspaces/${workspaceId}/searchindex`).on("child_removed", async (snapshot) => {
			let data = snapshot.val()
			await handleQueryResult({ data })
		})
	} catch (error) {
		console.log("ERROR - onWorkspaceEventstormingDeleted:")
		console.log(error)
	}
}

export const onWorkspaceEventstormingDeleted = async (workspaceId, handleQueryResult) => {
	try {
		firebase.database().ref(`/workspaces/${workspaceId}/stories`).on("child_removed", async (snapshot) => {
			let data = snapshot.val()
			await handleQueryResult({ data })
		})
	} catch (error) {
		console.log("ERROR - onWorkspaceEventstormingDeleted:")
		console.log(error)
	}
}

export const getEventstorming = (eventstormingId) => {
	try {
		firebase.database().ref(`/eventstorming/${eventstormingId}/state`).once("value")
			.then((snapshot) => {
				return Promise.resolve(snapshot.val())
			})
			.catch((error) => {
				if (error.code === "PERMISSION_DENIED") {
					return Promise.resolve(null)
				} else {
					return Promise.reject(error)
				}
			})
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getRole = async (eventstormingId, userId) => {
	try {
		if (!eventstormingId || !userId) {
			return null
		}
		let snapshot = await firebase.database().ref(`/eventstorming/${eventstormingId}/users/${userId}/role`).once("value")
		return snapshot.val()
	} catch (error) {
		if (error.code === "PERMISSION_DENIED") {
			return Promise.resolve(null)
		}
		return Promise.reject(error)
	}
}

export const deleteSolution = async (userUid, eventstormingId) => {
	try {
		await firebase.database().ref(`/workspaces/${userUid}/stories/${eventstormingId}`).remove()
	} catch(error) {
		if (error.code !== "PERMISSION_DENIED") {
			console.log(error)
		}
	}
}

