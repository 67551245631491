import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Typography from '@material-ui/core/Typography';
import Joyride, { EVENTS, ACTIONS } from 'react-joyride';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { openSnackbar } from '../Notifier';
import * as key from '../../constants/localStorage';

const styles = theme => ({
    joyrideList: {
        textAlign: "left",
        margin: "0.25em"
    },
    joyrideListItem: {
        marginBottom: theme.spacing.unit
    },
});


class EventstormingEditorTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepIndex: 0,
            doNotShowEslEditorTourAgain: false
        };
    }
    componentDidMount() {
        let doNotShowEslEditorTourAgain = localStorage.getItem(key.DO_NOT_SHOW_ESL_EDITOR_TOUR_AGAIN)
        if (doNotShowEslEditorTourAgain) {
            this.setState({ doNotShowEslEditorTourAgain: doNotShowEslEditorTourAgain })
        }
    };

    handleDoNotShowTourAgain = () => {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem(key.DO_NOT_SHOW_ESL_EDITOR_TOUR_AGAIN, true);
            openSnackbar({ message: "Tour will not be shown again." })
            this.setState({ doNotShowEslEditorTourAgain: !this.state.doNotShowEslEditorTourAgain })
            this.props.toggleEslEditorTourOpen()
        }
    }

    handleJoyrideCallback = (tour) => {
        const { action, index, type } = tour;
        if (type === EVENTS.TOUR_END) {
            // NOTE: This step doesn't work.  Tour ends and doesn't reset.
            this.props.toggleEslEditorTourOpen()
        }
        else if (action === ACTIONS.CLOSE) {
            this.setState({
                run: false,
                stepIndex: index
            });
            this.props.toggleEslEditorTourOpen()
        }
        else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // HACK: Comparing the next index to the size of the steps array
            let nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            if (nextIndex === tour.size) {
                this.setState({
                    stepIndex: 0,
                });
                this.props.toggleEslEditorTourOpen()
            }
            else {
                this.setState({ stepIndex: nextIndex });
            }
        }
    };
    static propTypes = {
        joyride: PropTypes.shape({
            callback: PropTypes.func
        })
    };
    static defaultProps = {
        joyride: {}
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>

                <Joyride
                    continuous scrollToFirstStep showProgress showSkipButton
                    run={this.props.run}
                    stepIndex={this.state.stepIndex}
                    spotlightClicks={true}
                    steps={[
                        {
                            title: "Understand system behaviour by creating timelines of events, and why they happen.",
                            content: <div>
                                <ul className={classes.joyrideList}>
                                    <li className={classes.joyrideListItem}><Typography variant="body2"><strong>Events:</strong> What happened?</Typography></li>
                                    <li className={classes.joyrideListItem}><Typography variant="body2"><strong>Commands:</strong> Why did it happen?</Typography></li>
                                    <li className={classes.joyrideListItem}><Typography variant="body2"><strong>Documents:</strong> What information is available at a point in time?</Typography></li>
                                    <li className={classes.joyrideListItem}><Typography variant="body2"><strong>Comments:</strong> What business rules apply? (... and anything else that's useful ...)</Typography></li>
                                </ul>
                                {this.state.doNotShowEslEditorTourAgain === false ? <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.doNotShowEslEditorTourAgain}
                                            onChange={this.handleDoNotShowTourAgain}
                                            value="doNotShowEslEditorTourAgain"
                                        />
                                    }
                                    label="Do Not Show This Intro Again"
                                /> : <div></div>}

                            </div>,
                            placement: "center",
                            disableBeacon: true,
                            styles: {
                                options: {
                                    zIndex: 10000
                                }
                            },
                            locale: { skip: "Skip Intro" },
                            target: "body"
                        },
                        {
                            title: "Collaborate",
                            content: <div>
                                <Typography variant="subtitle1">Story Stream remotely with other users.</Typography>
                            </div>,
                            styles: {
                                options: {
                                    zIndex: 10000,
                                }
                            },
                            locale: { skip: "Skip Intro" },
                            target: "#collaborationButton",
                            placement: "top"
                        },
                        {
                            title: "Sharing",
                            content: <div>
                                <Typography variant="subtitle1">Share read-only event stormings,</Typography>
                            </div>,
                            styles: {
                                options: {
                                    zIndex: 10000,
                                }
                            },
                            locale: { skip: "Skip Intro" },
                            target: "#shareButton",
                            placement: "top"
                        },
                        {
                            title: "Export Image",
                            content: <div>
                                <Typography variant="subtitle1">Export the graph as a .png image.</Typography>
                            </div>,
                            styles: {
                                options: {
                                    zIndex: 10000,
                                }
                            },
                            locale: { skip: "Skip Intro" },
                            target: "#exportPngButton",
                            placement: "top"
                        },
                    ]} callback={this.handleJoyrideCallback} />
            </React.Fragment>
        );
    }
}

EventstormingEditorTour.propTypes = {
    classes: PropTypes.object.isRequired,
    run: PropTypes.bool.isRequired,
};

export default compose(
    withStyles(styles),
)(EventstormingEditorTour);

